.left-sidebar{
    @include navigation(false, true);
    opacity: 0.2;
    background: $grey2;

    //$category-name-width: 30px;
    position: absolute;
    $top: 100px;
    top: $top;
    left: 0;
    display: none;
    &.show{
        display: block;
    }

    //.category-name{
    //    width: $category-name-width;
    //    word-wrap: break-word;
    //    letter-spacing: $category-name-width;
    //    font-size: $category-name-width;
    //    text-align: center;
    //    padding: 5px 5px 5px 0;
    //    background: $grey1;
    //    border-radius: 5px;
    //    overflow: hidden;
    //    @include centerHorizontal;
    //}

    .navigation {
        max-height: calc(100vh - #{$top} - 100px);

        .swiper-wrapper{
            display: block;
        }
    }

    &:hover{
        opacity: 1;
    }
    @include navigationArrows(false);
}