* {
    box-sizing: border-box;
}
body{
    font: {
        family: 'open sans', serif;
        size: 62.5%;
    }
    color: $white-light;
    background: $grey1;
    text-align: left;
    min-width: $site-width;
}
body, html{
    height: 100%;
    min-width: 500px;
}
h1{
    font-size: 4rem;
}
h2{
    font-size: 3rem;
}
h3{
    font-size: 2.5rem;
}
h4{
    font-size: 2rem;
}
h5{
    font-size: 1.2rem;
    font-weight: 600;
}
p{
    //font-size: 1.1rem;
}

img{
    display: block;
    max-width: 100%;
    max-height: 100%;
}