#fullpage-base {
    &, .section {
        width: 100%;
        height: 100%;
    }

    .section-homepage{
        background: url('../../media/bg/homepage.jpg') center no-repeat;
        background-size: cover;

        header{
            position: relative;
            width: 100%;
            text-align: right;

            .logo{
                max-width: 80vw;
                max-height: 80vh;
                display: inline-block;
            }
        }
        footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;

            .sublogo {
                max-width: 80vh;
                max-height: 50vh;
                display: inline-block;
                padding-bottom: 2%;
            }
        }
        .content{
            .menu{
                @include centerHorizontal();
                padding: 5%;

                li{
                    text-transform: uppercase;
                    font:{
                        size: 2.5rem;
                        weight: bold;
                    }
                    margin: 15px 0;

                    a{
                        text-shadow: 2px 2px 2px $grey2;
                        transition: all 0.3s ease;

                        &:hover{
                            margin-left: 5px;
                        }
                    }
                }
            }
            .contact{
                z-index: 2;
                padding: 0 5%;
                position: absolute;
                bottom: 5%;
                font-size: 1.8rem;
                text-shadow: 1px 1px 1px $grey2;

                .block{
                    display: inline-block;
                    padding-right: 20px;
                }
                .title{

                }

            }
        }
    }
}

%homepageNav{

}