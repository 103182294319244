@mixin navigation($horizontal: true, $showTitle:false){
    transition: all 0.5s ease;
    position: relative;
    border: 1px solid $grey2;
    border-radius: 5px;
    @if $horizontal{
        padding: 0 40px;
    }@else{
        padding: 40px 0;
    }

    .navigation {
        position: relative;
        padding: 5px;
        overflow: hidden;

        li {
            width: 95px;
            height: 95px;
            @if $horizontal {
                display: inline-block;
                margin: 0 10px 0 0;
            } @else {
                display: block;
                margin: 0 0 10px 0;
            }
            position: relative;

            &:last-of-type {
                margin: 0;
            }
            @if $showTitle {
                &:hover, &.active {
                    &:after {
                        box-sizing: border-box;
                        content: attr(data-title);
                        position: absolute;
                        bottom: 2px;
                        left: 2px;
                        width: calc(100% - 4px);
                        text-align: left;
                        padding: 2px 3px;
                        background: $grey2;
                    }
                }
            }
            &.active .item {
                border-color: $red;
            }

            .item {
                width: 95px;
                height: 95px;
                padding: 3px;
                border: 2px solid $white-light;
                border-radius: 3px;
                transition: all 0.5s ease;
                position: relative;
                display: block;
                overflow: hidden;
                cursor: pointer;
                background: {
                    position: center center;
                    size: cover;
                    repeat: no-repeat;
                }
                &:hover {
                    box-shadow: 0px 0px 6px 1px $white-light;
                }
            }
        }
    }
}

@mixin navigationArrows($horizontal: true){
    .prev, .next{
        z-index: 2;
    }
    .prev{
        $pos: 'top';
        @if $horizontal{
            $pos: 'left';
        }
        @include arrow($position: $pos);
    }
    .next{
        $pos: 'bottom';
        @if $horizontal {
            $pos: 'right';
        }
        @include arrow($position: $pos);
    }
}