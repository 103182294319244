section.content article{
    position: relative;
    overflow: hidden;
    height: 100vh;

    > header{
        position: relative;
        margin: 20px 30px 20px $left-sidebar-width;
        text-align: right;

        .title{
            font-size: 2rem;
            text-shadow: 1px 1px 1px $white-light;
            border-bottom: 1px solid $white-light;
            border-radius: 5px;
            display: inline-block;
            padding: 5px 10px 7px 10px;
            background: $grey2;

            small{
                opacity: 0.6;
            }
        }

    }
    .article-gallery{
        @include navigation();

        position: absolute;
        bottom: 20px;
        right: 30px;
        background: $grey2;
        max-width: calc(100vw - 200px);
        //.navigation {
        //    width: 300px;
        //    @media screen and (min-width: 600px){width: 340px;}
        //    @media screen and (min-width: 700px){width: 440px;}
        //    @media screen and (min-width: 800px){width: 540px;}
        //    @media screen and (min-width: 900px){width: 640px;}
        //    @media screen and (min-width: 1000px){width: 740px;}
        //}
        @include navigationArrows();
    }
    .gallery-content{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:{
            position: center center;
            size: contain;
            repeat: no-repeat;
        }
        z-index: 0;
    }
}