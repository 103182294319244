/* Reset CSS
		 * --------------------------------------- */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,
form,fieldset,input,textarea,p,blockquote,th,td {
    padding: 0;
    margin: 0;
}
table {
    border-spacing: 0;
}
fieldset,img {
    border: 0;
}
address,caption,cite,code,dfn,em,strong,th,var {
    font-weight: normal;
    font-style: normal;
}
strong{
    font-weight: bold;
}
ol,ul {
    list-style: none;
    margin:0;
    padding:0;
    border: none;
}
caption,th {
    text-align: left;

}
h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    font-size: 100%;
    margin:0;
    padding:0;
    color: $white-light;

    small{
        font-size: 65%;
    }
}
li{
    margin: 0;
    padding: 0;
    border: none;
}
a, .link{
    color: $white-light;
    text-decoration:none;

    &:hover{
        text-shadow: 0 0 1px $white-light;
    }
}