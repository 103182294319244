@import "arrows";
@import "small-gallery";

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

$black: #000000;
$grey1: #1a1a1a;
$grey2: #333333;
$red: #950000;
$white-light: #f1f1f1;

// set in fullpage script
$top-height: 8rem;
$top-height-small:  4rem;
$footer-height: 3.2rem;
$footer-height-small: 0;

// min width
$site-width: 500px;
$form-label-width: calc(90% - 150px);

//
$left-sidebar-width: 150px;

@mixin centerHorizontal() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
@mixin centerVertical() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
@mixin centerBoth() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}