/* arrow - default up */
@mixin arrow($position:'top', $anim: true){
    $deg: 0;
    @if $position=='top'{
        $deg: 0;
        top: 5px; left: 50%;
    }@elseif $position=='bottom'{
        $deg: 180;
        bottom: 5px; right: 50%;
    }@elseif $position=='left'{
        $deg: -90;
        bottom: 50%; left: 5px;
    }@elseif $position=='right'{
        $deg: 90;
        top: 50%; right: 5px;
    }

    position: absolute;
    width: 25px;
    height: 25px;
    margin: auto;
    opacity: 0.3;
    font-size: 0.5rem;
    transform: rotate(#{$deg}deg) translateX(-50%);
    transition: all 0.3s ease;

    &.active{
        cursor: pointer;
        opacity: 1;

        @if $anim {
            &:hover {
                transform: rotate(#{$deg}deg) translateX(-50%) translateY(-0.6em);
            }
        }
    }

    &:after, &:before {
        content: '';
        display: block;
        width: 0.6em;
        height: 100%;
        background: $white-light;
        transform: rotate(45deg) translateX(-50%);
        transform-origin: left top;
        margin: auto;
        position: absolute;
        top: 0;
        left: calc(50% + 0.15em);
        border-radius: 0 0 0.3em 0.3em;
    }

    &:before{
        transform: rotate(-45deg) translateX(-50%);
        left: calc(50% - 0.15em);
    }

}